import TagFilters from "./TagFilters";
import React from "react";
import {JurisdictionView} from "./Jurisdiction";

const _sortBy = require('lodash/sortBy');

let rowRender = ({label}) => <JurisdictionView name={label}/>;

const order = "Supreme Court,Federal Court,Alabama,Alaska,American Samoa,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,District Of Columbia,Florida,Georgia,Guam,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Northern Mariana Islands,Ohio,Oklahoma,Oregon,Pennsylvania,Puerto Rico,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virgin Islands,Virginia,Washington,West Virginia,Wisconsin,Wyoming".split(",");

function transformItems(items) {
  return _sortBy(items, item => order.indexOf(item.label));
}

export default ({...props}) => <TagFilters attribute="jurisdiction" title="Jurisdiction" rowRender={rowRender} transformItems={transformItems} limit={55} {...props}/>