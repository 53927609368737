import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

export const Share = () => {
  let url = window.location.href;

  return <div className="buttons">
    <FacebookShareButton url={url}>
      <FacebookIcon
        size={32}
        round/>
    </FacebookShareButton>
    <TwitterShareButton url={url}>
      <TwitterIcon
        size={32}
        round/>
    </TwitterShareButton>
    <LinkedinShareButton url={url}>
      <LinkedinIcon
        size={32}
        round/>
    </LinkedinShareButton>
    {/*<LinkedinShareButton/>*/}
  </div>
}