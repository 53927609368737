import React from "react";
import {Pagination, connectPagination} from "react-instantsearch-dom";
import {
  isBrowser
} from "react-device-detect";

export default class SearchResults extends React.Component {
  render() {
    return <Pagination padding={isBrowser ? 3 : 1} showFirst={false} showLast={false}
      translations={{
        previous: 'Previous',
        next: 'Next',
        first: 'First',
        last: 'Last',
      }} />
  }
}
