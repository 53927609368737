import React from "react";

const _uniqBy = require('lodash/uniqBy');
const _take = require('lodash/take');

var store = require('store');

const Recent = ({links, title, ...props}) => {
  if (!links.length)
    return null;

  return <div className="recent-list">
    <div className="subtitle">{title}:</div>
    <ol>
      {links.map(({title, href}) => <li key={href}><a href={href}>{title}</a></li>)}
    </ol>
  </div>
};

export const RecentView = ({sym, title}) => {
  let vals = store.get(sym) || [];

  return <Recent title={title} links={vals}/>
};

export function addRecent(listName, title, href) {
  let list = store.get(listName) || [];
  list.unshift({title, href});
  store.set(listName, _take(_uniqBy(list, 'title'), 10));
}
