/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= vite_javascript_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/modules/es6.promise.js";

import React from 'react'
import ReactDOM from 'react-dom';
import qs from 'qs';
import { createBrowserHistory } from 'history';

import {observable, computed, action} from 'mobx';
import {observer} from "mobx-react";

import TagFilters from "../components/TagFilters";

import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, Hits, Snippet, Configure, Index} from 'react-instantsearch-dom';
import Dropdown from "../components/Dropdown";
import TopSearch from "../components/TopSearch";
import SearchResults from "../components/SearchResults";
import SearchWithin from "../components/SearchWithin";
import {JurisdictionView} from "../components/Jurisdiction";
import JurisdictionFilter from "../components/JurisdictionFilter";

const _ = require('lodash');
const _get = require('lodash/get');
const _drop = require('lodash/drop');
const _sortBy = require('lodash/sortBy');
const _filter= require('lodash/filter');
const _debounce = require('lodash/debounce');

const history = createBrowserHistory();

import {
  BrowserView,
  MobileView,
  isMobile,
} from "react-device-detect";
import Tag from "../components/Tag";
import {addRecent, RecentView} from "../components/Recent";
import Stats from "../components/Stats";
import {Button} from "../components/Button";
import Level from "../components/Level";
import CategoryTag from "../components/CategoryTag";
import InfiniteHits from "../components/InfiniteHits";
import {Modal} from "../components/Modal";
import {Share} from "../components/Share";
var Mark = require('mark.js');

const searchClient = algoliasearch('OKPQ394LEA', '029a628a8e97effbac7ed51773fd0622');

class AppState {
  @observable post = gon.post;
  @observable markWords = true;
  @observable search = {};
  @observable showFilters = null;
  @observable searchingRelated = false;
  @observable tagSearching = false;
  @observable showRecent = false;
  @observable related = false;

  clearFilters = () => {
    let search = {...state.search};

    delete search.refinementList.tags;
    delete search.refinementList.category;
    delete search.refinementList.jurisdiction;

    this.setSearch(search);
  };

  setSearch = (searchState, redirect) => {
    state.search = searchState;

    let params = {};

    if (searchState.page > 1)
      params.page = searchState.page ;

    if (searchState.query && searchState.query.length)
      params.query = searchState.query;

    rlist(searchState, params, 'tags');
    rlist(searchState, params, 'category');
    rlist(searchState, params, 'jurisdiction');

    if ((state.post && !isMobile) || redirect) {
      location.href = "/?" + qs.stringify(params);
    }
    else
      historySet(params);
  };

  @computed get filtersNum() {
    let n=0;
    for(let k of ['tags', 'category', 'jurisdiction']) {
      let vals = _get(this.search, 'refinementList.' + k);
      if (vals)
        n += vals.length;
    }

    return n;
  }

  findRelated = async () => {
    state.searchingRelated = true;

    baseFindRelated(tags => {
      if (tags)
        state.setSearch({refinementList: {tags}}, true);
      else {
        state.searchingRelated = false;
        alert("No results found");
      }
    });
  }
}
let state = new AppState();

var store = require('store');

const historySet = _debounce(params => history.push("/" + "?" + qs.stringify(params), params), 500);

const baseFindRelated = async (onFound) => {
  let index = searchClient.initIndex(gon.indexPrefix + "_posts");

  let pick = (rest, prev) => {
    let next = rest.shift();

    if (!next) {
      onFound(prev);
      return;
    }

    let current = prev.concat(next);

    index.search({
        facetFilters: current.map(t => "tags:" + t),
      },
      function searchDone(err, content) {
        if (err) throw err;

        if (content.nbHits < 2)
          onFound(prev);
        else {
          pick(rest, current);
        }
      }
    );
  };

  let tags = state.post.tags.filter(t => t !== "Legal Questions" && t !== "Legal Definitions" && t !== "legal doctrines");
  let result = await Promise.all(tags.map(tag => index.search({
    facetFilters: ["tags:" + tag], // OR query
    facets: "tags",
  })));

  let counts = tags.map((t, i) => [t, result[i].nbHits]);
  let ordered = _sortBy(counts, t => t[1] * 1000 + t[0].charCodeAt(0)).filter(t => t[1] > 1).map(t => t[0]);

  if (ordered.length < 1)
    onFound();
  else
    pick(ordered, []);
};

if (state.post) {
  baseFindRelated(tags => {
    if (tags) {
      let index = searchClient.initIndex(gon.indexPrefix + "_posts");
      index.search({
          facetFilters: tags.map(t => "tags:" + t),
        },
        function searchDone(err, content) {
          let related = _.slice(_.reject(content.hits, { id: state.post.id }), 0, 10);

          if (related.length)
            state.related = related;
        });
    }
  })
}

function rlist(search, params, k) {
  let vals = _get(search, 'refinementList.' + k);

  if (vals)
    params[k] = vals.join("~");
}

let urlToSearchState = () => {
  const routeState = qs.parse(location.search.slice(1));
  let search = {
    refinementList: {},
    page: routeState.page || 1
  };

  if (routeState.query)
    search.query = routeState.query;

  if (routeState.tags)
    search.refinementList.tags = routeState.tags.split('~');

  if (routeState.category)
    search.refinementList.category = routeState.category.split('~');

  if (routeState.jurisdiction)
    search.refinementList.jurisdiction = routeState.jurisdiction.split('~');

  state.search = search;
};
urlToSearchState();

history.listen((location, action) => {
  urlToSearchState();
});



if (store.get('lastSearch')) {
  state.search = store.get('lastSearch');

  store.remove('lastSearch')
}

function clickPostTitle(hit) {
  store.set('lastSearch', state.search);
  addRecent('posts', hit.title, "/" + hit.url);
}

const Hit = ({hit}) => {
  return <div className="search-post">
    <h2 className="post-title">
      <a href={"/" + hit.url} onClick={() => clickPostTitle(hit)}>{hit.title}</a>
    </h2>
    <div>
      <JurisdictionView name={hit.jurisdiction}/>
      <CategoryTag category={hit.category}/>
    </div>
    <Snippet hit={hit} attribute="body"/>
  </div>;
};

@observer
class FiltersModalButton extends React.Component {
  onClick = () => {
    state.showFilters = this.props.sym;
  };

  render() {
    let {title, sym} = this.props;

    let tag = state.search.refinementList && state.search.refinementList[sym];
    tag = tag && tag.length;

    return <li className={state.showFilters === sym ? "is-active" : ""} onClick={this.onClick}>
      <a className="badge is-badge-danger">
        <span>{title}</span>
        {tag && <span className="tag">{tag}</span> }
      </a>
    </li>
  }
}


@observer
class RecentModal extends React.Component {
  close = () => state.showRecent = false;

  render() {
    if (!state.showRecent)
      return null;

    return <Modal onClose={this.close}>
      <RecentView sym="posts" title="Your Recently Viewed Posts" />
    </Modal>
  }
}

@observer
class FiltersModal extends React.Component {
  tagFocus = () => state.tagSearching = true;
  tagBlur = () => state.tagSearching = false;

  close = () => {
    if (state.post && isMobile)
      location.reload();
    else
      state.showFilters = null;
  };

  footer() {
    if (state.tagSearching)
      return;

    return <footer className="modal-card-foot">
      <button className="button is-success" onClick={this.close}>Done</button>
    </footer>
  }

  render() {
    return <div className={`modal modal-full-screen filters-modal ${state.showFilters && "is-active"}`}>
      <div className="modal-background"/>
      <div className="modal-card">
        <header className="modal-card-head">
          <div style={{flex: 1}}>
            <div className="modal-card-title">
              <Level className="level is-mobile">
                <Stats/>
                <Button title="Clear all" css="is-link is-inverted" onClick={state.clearFilters}/>
              </Level>
            </div>
            <div className="tabs is-toggle is-fullwidth">
              <ul>
                <FiltersModalButton title="Jurisdiction" sym="jurisdiction"/>
                <FiltersModalButton title="Categories"  sym="category"/>
                <FiltersModalButton title="Tags"  sym="tags"/>
              </ul>
            </div>
          </div>
        </header>
        <section className={"modal-content show-" + state.showFilters}>
          <TagFilters attribute="tags" operator="and" limit={51} hidden={state.showFilters !== "tags"} searchable="find more tags"
                      searchProps={{
                        onFocus: this.tagFocus,
                        onBlur: this.tagBlur
                      }}
          />
          <TagFilters attribute="category" title="Category" hidden={state.showFilters !== "category"}/>
          <JurisdictionFilter hidden={state.showFilters !== "jurisdiction"}/>
        </section>
        {this.footer()}
      </div>
    </div>
  }
}


class Menu extends React.Component {
  render() {
    let props = {};
    if (isMobile)
      props.icon = "menu";
    else {
      props.down = true;
      props.title = "Menu";
    }

    let items = [
      {title: "About", href: "/about"},
      {title: "Features", href: "/features"},
      {title: "Legal Definitions", href: "/definitions"},
      {title: "Legal Questions", href: "/questions"},
      {title: "Legal Doctrines", href: "/doctrines"},
      {title: "Contact", href: "/contact"},
    ];

    if (isMobile)
      items.push({title: "Recently Viewed Posts", onClick: () => state.showRecent = true});

    return <div>
      <RecentModal />
      <Dropdown {...props} items={items} />
    </div>
  }
}

class PostTag extends React.Component {
  render() {
    let {tag} = this.props;

    return <Tag name={tag}/>
  }
}

@observer
class Post extends React.Component {
  componentDidMount() {
    this.componentDidUpdate();
  }

  expandRegexp(tags, exact) {
    let ignore = "[.'\"`,]*";
    let compress = "[\\n\\r\\\\t&;:()\\[\\]\\/\\- ]+";

    let regexp = tags.map(t => t.split("").join(ignore).split(/ /).join(compress)).join("|");

    if (exact)
      regexp = `(^|\\W)(${regexp})(\\W|$)`;

    return new RegExp(regexp, "i");
  }

  componentDidUpdate() {
    let marker = new Mark("div.post-body");

    if (!this.props.markWords) {
      marker.unmark();
      return;
    }

    let opts = {
      ignorePunctuation: ".'\"`,".split(""),
      separateWordSearch: false,
    };

    let [exact, fuzzy, query] = this.markWords();

    if (query.length)
      marker.mark(query, {className: 'query-mark', ...opts});

    if (fuzzy.length)
      marker.markRegExp(this.expandRegexp(fuzzy), opts);

    if (exact.length)
      marker.markRegExp(this.expandRegexp(exact, true), {accuracy: 'exactly', ...opts});

  }

  markWords() {
    if (!state.post)
      return [[], []];

    if (this.toMark)
      return this.toMark;

    let query = _get(state, "search.query");
    let tags = new Set(_get(state, "search.refinementList.tags"));

    let selectedTags = _.filter(gon.tags, t => tags.has(t.name));

    let exact = _.flatMap(selectedTags, 'exact');
    let fuzzy = _.flatMap(selectedTags, 'fuzzy');

    this.toMark = [exact, fuzzy, query ? query.split(/ /) : []];

    return this.toMark
  }

  toggleMarkWords = () => {
    state.markWords = !state.markWords;
  };

  renderSwitch() {
    if (!this.markWords())
      return null;

    return <div className="field highlight-switch" onClick={this.toggleMarkWords}>
      <input type="checkbox" className="switch" checked={state.markWords} onChange={this.toggleMarkWords}/>
      <label htmlFor="switchExample">Highlight Text</label>
    </div>;
  }

  render() {
    let {hit} = this.props;

    return <div className="search-post">
      <SearchWithin/>
      <Level>
        <h2 className="post-title">
          {hit.title}
          {gon.admin && <a href={`/posts/${gon.post.id}/edit`}>edit</a>}
        </h2>
        {this.renderSwitch()}
      </Level>
      <Level>
        <>
          <JurisdictionView name={hit.jurisdiction}/>
          <CategoryTag category={hit.category}/>
        </>
        { !isMobile && <FindRelatedBtn /> }
      </Level>
      <div className="post-body">
        {hit.body.replace(/<.+?>/g, '').split("\n").map(line => <p>{line}</p>)}
      </div>
      <br />
      <div>
        <FindRelatedBtn />
        { state.related &&
        <>
          <br />
          <br />
          <b>You might also be interested in:</b>
          <br />
          <br />
          {state.related.map(post => <Hit hit={post} />)}
        </>
        }
      </div>
      <br />
      <div className="tags">
        {hit.tags.map(t => <PostTag tag={t} key={t} />)}
      </div>

      <Share />
    </div>;
  }
}

const FindRelatedBtn = ({css}) => <Button css={css || "is-primary is-danger"} title="Find Related Documents" onClick={state.findRelated} loading={state.searchingRelated}/>;

@observer
class Main extends React.Component {
  searchArea() {
    return <>
      <SearchWithin/>
      <div className="results">
        <Stats/>
        <Hits hitComponent={Hit}/>
        <SearchResults/>
      </div>
    </>
  }

  postArea() {
    return <Post hit={state.post} markWords={state.markWords}/>
  }

  inner() {
    if (state.post)
      return this.postArea();
    else
      return this.searchArea();
  }

  mobileBtns() {
    if (state.post)
        return <>
          <button className="button mobile-filter-button is-info is-large is-fullwidth badge is-badge-danger" data-badge={state.filtersNum || null}
                  onClick={() => state.showFilters = "jurisdiction"}>
            Filters
          </button>
          <FindRelatedBtn css="button mobile-filter-button is-danger"/>
        </>;
      else
        return <button className="button is-info is-large is-fullwidth badge is-badge-danger" data-badge={state.filtersNum || null}
                                     onClick={() => state.showFilters = "jurisdiction"}>
          Filters
        </button>

  }

  render() {
    let tagsTitle = state.filtersNum > 0 || state.search.query ? "Tags" : "Search by legal term" ;

    return <InstantSearch searchClient={searchClient}
                          searchState={state.search}
                          onSearchStateChange={state.setSearch}
                          indexName={gon.indexPrefix + "_posts"}>
      <Configure
        hitsPerPage={isMobile ? 4 : 10}
      />
      <div className="columns is-variable is-4">
        <div className="column is-3">
          <BrowserView>
            <div className="box">
              <Level>
                <div className="subtitle has-text-grey">Filters</div>
                <Button title="Clear all" css="is-link is-inverted" onClick={state.clearFilters}/>
              </Level>
              <TagFilters attribute="category" title="Category"/>
              <br/>
              <JurisdictionFilter/>
            </div>
            <div className="recent-box">
              <RecentView sym="posts" title="Your Recently Viewed Posts" />
              <br />
              <RecentView sym="searches" title="Your Recent Searches" />
            </div>
          </BrowserView>
          <MobileView>
            {this.mobileBtns()}
            <FiltersModal/>
          </MobileView>
        </div>
        <div className="column is-6">
          {this.inner()}
        </div>
        <div className="column is-3">
          <BrowserView>
            <div className="box">
              <TagFilters attribute="tags" operator="and" limit={51} title={tagsTitle} searchable="find more tags"/>
            </div>
          </BrowserView>
        </div>
      </div>
    </InstantSearch>
  }
}

function activate(id, component) {
  let div = document.getElementById(id);

  if (div)
    ReactDOM.render(component, div);
}

setTimeout(() => {
  activate('top-search', <TopSearch/>);
  activate('menu', <Menu/>);
  activate('app', <Main/>);
}, 0);
