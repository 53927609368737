import React from "react";

class Item extends React.Component {
  click = () => {
    let {value, onClick, toggle} = this.props;

    toggle();
    if (value)
      onClick(value);
    else
      onClick();
  };

  render() {
    let {title, value, toggle, onClick, ...props} = this.props;

    return <a className="dropdown-item" {...props} onClick={this.click}>
      {title}
    </a>;
  }
}

class Dropdown extends React.Component {
  state={active: false};

  toggle = () => this.setState({active: !this.state.active});

  render() {
    let {title, icon, down, items, ...props} = this.props;

    if (down)
      icon = 'chevron-down';

    return <div className={`dropdown is-right ${this.state.active && "is-active"}`} { ...props}>
      <div className="dropdown-trigger">
        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu"  onClick={this.toggle}>
          { title && <span>{title}</span> }
          {
            icon && <span className="icon is-small">
            <i className={`mdi mdi-${icon}`} aria-hidden="true"/>
          </span>
          }
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {items.map(i => <Item {...i} toggle={this.toggle} key={i.title}/>)}
        </div>
      </div>
    </div>
  };
}


export default Dropdown;