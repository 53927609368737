import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

class InfiniteHits extends Component {
  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, hitComponent, hasMore } = this.props;

    return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list">
          {hits.map(hit => (
            <li key={hit.objectID} className="ais-InfiniteHits-item">
              {React.createElement(hitComponent, { hit })}
            </li>
          ))}
          <li
            className="ais-InfiniteHits-sentinel"
            ref={c => (this.sentinel = c)}
          >
            { hasMore && <progress className="progress is-small" max="100" /> }
          </li>
        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);