import React from "react";
const titleize = require('titleize');

const _snakeCase = require('lodash/snakeCase');

export let JurisdictionView = ({code, name}) => {
  let title = titleize(name);

  if (title === 'Federal Court')
    title = "Other Federal Courts";

  if (title === 'Supreme Court')
    title = "U.S. Supreme Court";

  return <span className="jurisdiction">
    <img src={"/us-state-flags/" + _snakeCase(name) + ".png"}/>
    &nbsp;{title}
  </span>
};

