import React from "react";

export default class CheckboxTable extends React.Component {
  row(item) {
    let {onClick, rowRender} = this.props;

    return <div className="filter-row"
                onClick={() => onClick(item)}
                key={item.label}>
      <label className="checkbox">
        <input type="checkbox" checked={item.isRefined} onChange={() => onClick(item)}/>
      </label>
      <div className="filter-row-content">{rowRender(item)}</div>
      <div>{item.count.toLocaleString()}</div>
    </div>
  }

  render() {
    let {title, items, hidden, searchable, searchForItems, searchProps} = this.props;

    return <div className={"filter-box " + (hidden && "hidden")}>
      <div className="subtitle">{title}</div>
      {searchable && <input className="input"
                            placeholder={searchable}
                            type="search"
                            {...searchProps}
                            onChange={event => searchForItems(event.currentTarget.value)}
      />}
      {items.map(item => this.row(item))}
    </div>
  }
}