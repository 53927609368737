import React from "react";
import {Index, InstantSearch} from "react-instantsearch-dom";
import {Highlight, Snippet, Configure, connectAutoComplete} from 'react-instantsearch-dom';
import AutoSuggest from './AutoSuggest';
import algoliasearch from "algoliasearch/lite";
import {addRecent} from "./Recent";
import {JurisdictionView} from "./Jurisdiction";
import {isMobile} from "react-device-detect";

const searchClient = algoliasearch('OKPQ394LEA', '029a628a8e97effbac7ed51773fd0622');

const _ = require('lodash');
const _filter = require('lodash/filter');
const _sortBy = require('lodash/sortBy');

var store = require('store');

function newQuery(query) {
  if (query.length) {
    let newHref = "/?query=" + query;
    addRecent('searches', query, newHref);

    location.href = newHref;
  }
}

function clickTag(tag) {
  let newHref = "/?tags=" + tag.name;
  addRecent('searches', tag.name, newHref);
  location.href = newHref;
}

function clickPost(post, query) {
  let newHref = "/" + post.url;
  addRecent('posts', post.title, newHref);
  store.set('lastSearch', {query});
  location.href = newHref;
}

class AutoComplete extends React.Component {
  state = {
    value: this.props.currentRefinement,
  };

  search = () => {
    newQuery(this.state.value);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (event, {newValue, method}) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({value}) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    // keep suggestions open when blur on mobile
    // this.props.refine();
  };

  getSuggestionValue(hit) {
    return hit.name || hit.title;
  }

  renderSuggestion = (hit) => {
    if (hit.name)
      return <div onClick={() => clickTag(hit)}>
        <i className="mdi mdi-tag"></i>
        <Highlight attribute="name" hit={hit} tagName="mark"/>
      </div>;
    else
      return <div onClick={() => clickPost(hit, this.state.value)}>
        <div><b><Highlight attribute="title" hit={hit} tagName="mark"/></b></div>
        <div>
          <JurisdictionView name={hit.jurisdiction}/>
          <span className="is-size-7"> ({hit.category.toLowerCase()})</span>
        </div>
        <Snippet hit={hit} attribute="body"/>
      </div>
  };

  renderSectionTitle(section) {
    return section.index.match(/posts/) ? "Case Summaries & Articles" : "Tags";
  }

  getSectionSuggestions(section) {
    return section.hits;
  }

  shouldRenderSuggestions(value) {
    return value.trim().length > 0;
  }

  blurInputOnMove = () => {
    let el = document.activeElement;
    if (el && el.type === "text")
      el.blur();

    return true;
  };

  // renderSuggestionsContainer = ({ containerProps , children, query }) => {
  //   containerProps.onTouchMove = this.blurInputOnMove;
  //   return <div {... containerProps}>
  //     {children}
  //   </div>
  // };

  render() {
    const {hits, onSuggestionSelected} = this.props;
    const {value} = this.state;

    const inputProps = {
      placeholder: 'Enter case or keyword...',
      onKeyDown: this.onKeyDown,
      onChange: this.onChange,
      onTouchMove: () => {},
      value,
    };

    let results = _.reverse(_sortBy(_filter(hits, "hits.length"), 'index'));

    return (
      <AutoSuggest
        suggestions={results}
        multiSection={true}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        alwaysRenderSuggestions={isMobile && !!value.length}
        renderSectionTitle={this.renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        renderInputComponent={inputProps => <div className="field has-addons">
          <p className="control has-icons-right top-search-control">
            <input className="input" {...inputProps} />
            <span className="icon is-small is-right">
            {/*<i className="mdi mdi-magnify" onClick={() => alert(1)}></i>*/}
    </span>
          </p>
          <p className="control">
            <a className="button" onClick={this.search}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#ddd"
                                                                                                       d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"/></svg>
              <span> Search</span>
            </a>
          </p>
        </div>
        }
      />
    );
  }
}

const ConnectedAutoComplete = connectAutoComplete(AutoComplete);

export default class TopSearch extends React.Component {
  render() {
    return <InstantSearch searchClient={searchClient} indexName={gon.indexPrefix + "_posts"}>
      <ConnectedAutoComplete/>
      <Index indexName={gon.indexPrefix + "_tags"}>
        <Configure
          hitsPerPage={5}
          distinct
        />
      </Index>
      <Index indexName={gon.indexPrefix + "_posts"}>
        <Configure
          attributesToSnippet={['body:30', 'title:20']}
          hitsPerPage={10}
          analytics={false}
          distinct
        />
      </Index>
    </InstantSearch>
  }
}

