import React from "react";
import CheckboxTable from "./CheckboxTable"
import {connectRefinementList} from 'react-instantsearch-dom';

import {
  isBrowser,
} from "react-device-detect";

const RefinementList = ({
                          items,
                          rowRender,
                          isFromSearch,
                          refine,
                          currentRefinement,
                          title,
                          ...props


                        }) => {
  let onClick = ({value}) => refine(value);
  rowRender = rowRender || (({label}) => label);

  return <CheckboxTable items={items}
                        currentRefinement={currentRefinement}
                        title={isBrowser && title}
                        onClick={onClick}
                        rowRender={rowRender}
                        {...props}
  />;
};


export default connectRefinementList(RefinementList);