import React from "react";

const Level = ({left, right, children, ...props}) => {
  if (!children.length)
    children = [children];

  return <div className='level' style={{marginBottom: "0.5rem"}} {...props}>
    <div className='level-left'>{left || children[0]}</div>
    <div className='level-right'>{right || children[1]}</div>
  </div>
};

export default Level;

