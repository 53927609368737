import {Stats} from "react-instantsearch-dom";
import React from "react";

export default () => <Stats
  translations={{
    stats(nbHits, timeSpentMS) {
      return <span><span className="results-num">{nbHits.toLocaleString()}</span> results found</span>
    },
  }}
/>
